import React from 'react';
import {
  ArrayParam,
  BooleanParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import Layout from '../../../../modules/layout';
import ResultsList from '../../../../components/ui/resultsList';
import { useWinnerFilters } from '../../../../hooks/useWinnerFilters';
import * as styles from './styles.module.scss';
import Container from '../../../../modules/container';
import Headline from '../../../../components/ui/headline';
import Text from '../../../../components/ui/text';
import { getCopy } from '../../../../helpers/copy';
import { useLocale } from '../../../../context/locale.context';
import Search from '../../../../components/ui/filterSearch';

const SIPWinnerOverview = () => {
  const { locale } = useLocale();
  const [query, _] = useQueryParams({
    tagId: StringParam,
    awardId: ArrayParam,
    regionId: ArrayParam,
    countryId: ArrayParam,
    disciplineId: ArrayParam,
    categoryId: ArrayParam,
    yearId: ArrayParam,
    sort: StringParam,
    find: StringParam,
    inspiration: BooleanParam,
  });
  const { allContentfulMicrocopy } = useStaticQuery(
    graphql`
      query {
        allContentfulMicrocopy {
          nodes {
            node_locale
            key
            value
          }
        }
      }
    `
  );

  const onSubmit = (searchTerm) => {
    navigate(searchTerm, {
      replace: true,
    });
  };

  const searchByTag = (tag) => {
    navigate(`?tagId=${tag}`, {
      replace: true,
    });
  };

  const resetSearchTerm = () => navigate('?', { replace: true });

  const onInspire = () => {
    navigate('?inspiration=1', { replace: true });
  };

  const microcopy = allContentfulMicrocopy.nodes
    .filter((node) => node.node_locale === locale)
    .reduce((acc, curr) => {
      return {
        ...acc,
        [curr.key]: curr.value,
      };
    }, {});

  const queryFilters = {
    awards: query.awardId ? query.awardId.map(Number) : [5],
    disciplines: query.disciplineId ? query.disciplineId.map(Number) : [],
    categories: query.categoryId ? query.categoryId.map(Number) : [],
    region: query.regionId ? query.regionId.map(String) : [],
    countries: query.countryId ? query.countryId.map(String) : [],
    time: query.yearId ? query.yearId.map(Number) : [0],
    inspiration: query.inspiration,
    tag: query.tagId,
    sort: query.sort ? query.sort : 'desc',
    find: query.find,
  };

  return (
    <Layout
      title={'title.SIPWinnerOverview'}
      description={'desc.SIPWinnerOverview'}
      slug={'social-engagement/sip/winner-overview'}
    >
      <div className={styles.headlineOuterContainer}>
        <Container className={styles.headlineContainer}>
          <Headline
            text={getCopy('label.sipFilterHeadline', microcopy)}
            level="h1"
            type="h1"
          />
          <Text as="p" type="p1" className={styles.subheadline}>
            {getCopy('label.sipFilterSubheadline', microcopy)}
          </Text>
        </Container>
      </div>
      <Search
        onSubmit={onSubmit}
        onTagClick={searchByTag}
        resetSearchTerm={resetSearchTerm}
        onInspire={onInspire}
        initSearchTerm={query.find}
        type="sip"
        placeholder={getCopy('placeholder.whatDoYouWantToExplore', microcopy)}
      />
      <ResultsList
        queryFilters={queryFilters}
        filtersHook={useWinnerFilters}
        filtersApiParam="sip"
        noResultsLabelKey="results.projects.noResults"
        teaserType="design"
        type="sip"
      />
    </Layout>
  );
};

export default SIPWinnerOverview;
